import i18next from 'i18next';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Key, useEffect, useState } from 'react';
import { EventConfig } from './model';
import { LandingPage } from './pages';

function App() {

  // Event data
  const [ landingpageId, setLandingpageId ] = useState<string>();
  const [ baseUrl, setBaseUrl ] = useState<string>();
  const [ eventConfig, setEventConfig ] = useState<EventConfig>();
  const [ key, setKey ] = useState<Key>('');

  useEffect(
    () => {
      const hostName =
        process.env.REACT_APP_OVERWRITE_HOSTNAME
        ?? window.location.hostname;
      
      if (hostName.split('.').length == 3) {
        setLandingpageId(hostName.split('.')[0]);
        setBaseUrl(hostName);
      } else {
        redirectToWebsite();
      }

      i18next.on(
        'languageChanged', (lng) => {
          document.documentElement.lang = lng;
          setKey((new Date()).toISOString());
        }
      );
    }
  );

  // Loads the event data.
  useEffect(
    () => {
      if (!landingpageId)
        return;

      fetch(`/events/${landingpageId}/event.json`)
        .then(
          async (responseValue) => {
            try {
              const response = await responseValue.json();
              if (response && responseValue.ok) {
                setEventConfig(response);
              } else {
                redirectToWebsite();
              }
            } catch {
              redirectToWebsite();
            }
          }
        );
    },
    [landingpageId]
  );

  /**
   * Redirects the user to our main website.
   */
  function redirectToWebsite() {
    window.location.href = "https://fiesta.club";
  }

  return (
    <div>
      {
        eventConfig
        && baseUrl
        &&
        <LandingPage
          eventConfig={eventConfig}
          baseUrl={baseUrl}
          key={key}
        />
      }
      {
        !eventConfig
        &&
        <div className="flex flex-column justify-content-center align-items-center" style={{height: '100%'}}>
          <ProgressSpinner />
        </div>
      }
    </div>
  );
}
export default App;
