import { t } from "i18next";
import { EventConfig } from "../../model";

export type MainContentProps = {
  eventConfig: EventConfig;
}

export function MainContent(props: MainContentProps) {
  const { eventConfig } = props;

  /**
   * Redirects the user to the target.
   */
  function redirectToTarget() {
    console.debug('Redirecting to target...');
    window.location.href = props.eventConfig.target ?? "#";
  }

  return <>
    <main className="page-body">
      <section id="hero">
          <div className="container content-container">
            <div className="content">
              <h1>{ t('header.title') }</h1>
              <h2 className="subtitle">{ t('header.subTitle') }</h2>
              <button
                disabled={eventConfig.mode != 'live'}
                className={(eventConfig.mode == 'live' ? 'mt-4 enabled' : 'mt-4 disabled')}
                onClick={() => redirectToTarget()}
              >
                { 
                  eventConfig.mode == 'live'
                    ? t('header.button')
                    : t('header.comingSoon')
                }
              </button>
            </div>
          </div>
          <div className="img-container">
            <div className="img-overlay"></div>
            <div className="img"></div>
          </div>
      </section>
      <section id="features" className="flex flex-row flex-column lg:flex-row flex-wrap">
        <div className="flex flex-column flex-1" />

        <div className="flex flex-column flex-1 align-items-center">
          {/* Register Icon */}
          <svg className="rowIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <circle cx="11" cy="8" opacity=".3" r="2" />
            <path d="M5 18h4.99L9 17l.93-.94C7.55 16.33 5.2 17.37 5 18z" opacity=".3" />
            <path
              d="M11 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm-1 12H5c.2-.63 2.55-1.67 4.93-1.94h.03l.46-.45L12 14.06c-.39-.04-.68-.06-1-.06-2.67 0-8 1.34-8 4v2h9l-2-2zm10.6-5.5l-5.13 5.17-2.07-2.08L12 17l3.47 3.5L22 13.91z"
            />
          </svg>
          <h3>{t('features.register.title')}</h3>
          <p>{t('features.register.text')}</p>
        </div>

        <div className="flex flex-column flex-1 align-items-center">
          {/* Sell Icon */}
          <svg className="rowIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M15.55 11l2.76-5H6.16l2.37 5z" opacity=".3" />
            <path
              d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"
            />
          </svg>
          <h3>{t('features.sell.title')}</h3>
          <p>{t('features.sell.text')}</p>
        </div>

        <div className="flex flex-column flex-1 align-items-center">
          {/* Experience Icon */}
          <svg className="rowIcon" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill="currentColor">
            <g><rect fill="none" height="24" width="24" /></g>
            <g>
              <g>
                <path
                  d="M18.49,9.88l0.08-0.85l0.18-1.95l-1.9-0.43l-0.84-0.19l-0.44-0.74l-0.99-1.68L12.79,4.8L12,5.14L11.21,4.8 L9.42,4.03L8.43,5.71L7.99,6.45L7.15,6.64l-1.9,0.43l0.18,1.94l0.08,0.85l-0.56,0.65l-1.29,1.48l1.29,1.47l0.56,0.65l-0.08,0.85 l-0.18,1.96l1.9,0.43l0.84,0.19l0.44,0.74l0.99,1.67l1.78-0.77L12,18.85l0.79,0.34l1.78,0.77l0.99-1.68l0.44-0.74l0.84-0.19 l1.9-0.43l-0.18-1.95l-0.08-0.85l0.56-0.65l1.29-1.47l-1.29-1.47L18.49,9.88z M10.09,16.72l-3.8-3.81l1.48-1.48l2.32,2.33 l5.85-5.87l1.48,1.48L10.09,16.72z"
                  opacity=".3"
                />
                <path
                  d="M23,11.99l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,11.99l2.44,2.79 l-0.34,3.7l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,11.99z M19.05,13.47l-0.56,0.65l0.08,0.85 l0.18,1.95l-1.9,0.43l-0.84,0.19l-0.44,0.74l-0.99,1.68l-1.78-0.77L12,18.85l-0.79,0.34l-1.78,0.77l-0.99-1.67l-0.44-0.74 l-0.84-0.19l-1.9-0.43l0.18-1.96l0.08-0.85l-0.56-0.65l-1.29-1.47l1.29-1.48l0.56-0.65L5.43,9.01L5.25,7.07l1.9-0.43l0.84-0.19 l0.44-0.74l0.99-1.68l1.78,0.77L12,5.14l0.79-0.34l1.78-0.77l0.99,1.68l0.44,0.74l0.84,0.19l1.9,0.43l-0.18,1.95l-0.08,0.85 l0.56,0.65l1.29,1.47L19.05,13.47z"
                />
                <polygon points="10.09,13.75 7.77,11.42 6.29,12.91 10.09,16.72 17.43,9.36 15.95,7.87" />
              </g>
            </g>
          </svg>
          <h3>{t('features.experience.title')}</h3>
          <p>{t('features.experience.text')}</p>
        </div>
        
        <div className="flex flex-column flex-1" />
      </section>
      <section id="cta">
        <div className="content-container container">
          <div>
            <p>{t('cta.title')}</p>
            <p>{t('cta.title2')}</p>
            <button
              disabled={eventConfig.mode != 'live'}
              className={(eventConfig.mode == 'live' ? 'enabled' : 'disabled')}
              onClick={() => redirectToTarget()}
            >
              {
                eventConfig.mode == 'live'
                  ? t('cta.button')
                  : t('cta.comingSoon')
              }
            </button>
          </div>
        </div>
        <div className="image-container">
          <div className="image"></div>
        </div>
      </section>
    </main>
  </>;
}