import i18next, { changeLanguage, t } from "i18next";
import { EventConfig } from "../../model";

export type FooterContentProps = {
  eventConfig: EventConfig;
}

export function FooterContent(props: FooterContentProps) {
  // Extract the event configuration from props.
  const { eventConfig } = props;

  return <>
    <footer className="container">
      <div className="info">
        <div className="socials">
          {
            // Facebook Icon.
            eventConfig.socialConfig?.showFacebook
            &&
            <a href={ eventConfig.socialConfig.facebook ?? '#' } target="_blank">
              <svg className="socialIcon" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24">
                <path d="M19,3H5C3.895,3,3,3.895,3,5v14c0,1.105,0.895,2,2,2h7.621v-6.961h-2.343v-2.725h2.343V9.309 c0-2.324,1.421-3.591,3.495-3.591c0.699-0.002,1.397,0.034,2.092,0.105v2.43h-1.428c-1.13,0-1.35,0.534-1.35,1.322v1.735h2.7 l-0.351,2.725h-2.365V21H19c1.105,0,2-0.895,2-2V5C21,3.895,20.105,3,19,3z"></path>
              </svg>
            </a>
          }
          {
            // Instagram Icon.
            eventConfig.socialConfig?.showInstagram
            &&
            <a href={ eventConfig.socialConfig.instagram ?? '#' } target="_blank">
              <svg className="socialIcon" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24">
                <path d="M 8 3 C 5.239 3 3 5.239 3 8 L 3 16 C 3 18.761 5.239 21 8 21 L 16 21 C 18.761 21 21 18.761 21 16 L 21 8 C 21 5.239 18.761 3 16 3 L 8 3 z M 18 5 C 18.552 5 19 5.448 19 6 C 19 6.552 18.552 7 18 7 C 17.448 7 17 6.552 17 6 C 17 5.448 17.448 5 18 5 z M 12 7 C 14.761 7 17 9.239 17 12 C 17 14.761 14.761 17 12 17 C 9.239 17 7 14.761 7 12 C 7 9.239 9.239 7 12 7 z M 12 9 A 3 3 0 0 0 9 12 A 3 3 0 0 0 12 15 A 3 3 0 0 0 15 12 A 3 3 0 0 0 12 9 z"></path>
              </svg>
            </a>
          }
          {
            // Twitter Icon.
            eventConfig.socialConfig?.showTwitter
            &&
            <a href={ eventConfig.socialConfig.twitter ?? '#' } target="_blank">
              <svg className="socialIcon" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24">
                <path d="M10.053,7.988l5.631,8.024h-1.497L8.566,7.988H10.053z M21,6v12	c0,1.657-1.343,3-3,3H6c-1.657,0-3-1.343-3-3V6c0-1.657,1.343-3,3-3h12C19.657,3,21,4.343,21,6z M17.538,17l-4.186-5.99L16.774,7	h-1.311l-2.704,3.16L10.552,7H6.702l3.941,5.633L6.906,17h1.333l3.001-3.516L13.698,17H17.538z"></path>
              </svg>
            </a>
          }
        </div>
        
        {/* Language Switcher */}
        <div className="language">
          <span>
            <a className="mr-1" onClick={() => i18next.changeLanguage('de')}>DE</a>|
            <a className="ml-1" onClick={() => i18next.changeLanguage('en')}>EN</a>
          </span>
        </div>

        {/* Legal Links */}
        <div className="links">
          <a href="https://fiesta.club/imprint">{ t('footer.imprint') }</a>
          <a href="https://fiesta.club/private-policy">{ t('footer.privacy') }</a>
        </div>
      </div>

      {/* Copyright message */}
      <div className="copyright">
        <p>{t('footer.copyright')}</p>
        {
          eventConfig.mission
          &&
          <small>{t('footer.mission', { mission: eventConfig.mission})}</small>
        }
      </div>
    </footer>
  </>;
}