import { useEffect, useState } from "react";
import { EventConfig } from "../model";
import { MainContent } from "./components";
import { FooterContent } from "./components/FooterContent";
import { t } from "i18next";

export type LandingPageProps = {
  eventConfig: EventConfig;
  baseUrl: string;
}

export function LandingPage(props: LandingPageProps) {

  const { eventConfig, baseUrl } = props;

  const [ primaryColor, setPrimaryColor ] = useState<string>('#E5006E');
  const [ secondaryColor, setSecondaryColor ] = useState<string>('#DBF227');
  const [ highlightColor, setHighlightColor ] = useState<string>('#10285E');

  const [ imageGimmick, setImageGimmick ] = useState<string>('/img/gimmick.png');
  const [ imageHeader, setImageHeader ] = useState<string>('/img/header.jpg');
  const [ imageSquare, setImageSquare ] = useState<string>('/img/square.jpg');

  useEffect(
    () => {
      if (!eventConfig)
        return;

      // Load colors if available.
      if (eventConfig.colorConfig) {
        if (eventConfig.colorConfig.primary)
          setPrimaryColor(eventConfig.colorConfig.primary);

        if (eventConfig.colorConfig.secondary)
          setSecondaryColor(eventConfig.colorConfig.secondary);

        if (eventConfig.colorConfig.highlight)
          setHighlightColor(eventConfig.colorConfig.highlight);
      }

      // Load images if available.
      if (eventConfig.logoConfig) {
        if (eventConfig.logoConfig.gimmick)
          setImageGimmick(eventConfig.logoConfig.gimmick);

        if (eventConfig.logoConfig.header)
          setImageHeader(eventConfig.logoConfig.header);

        if (eventConfig.logoConfig.square)
          setImageSquare(eventConfig.logoConfig.square);
      }
    },
    [ eventConfig ]
  )

  return <>
    <style>
      {
        `

        body {
          background-color: ${primaryColor}!important;
          color: ${secondaryColor}!important;
        }

        button {
          background-color: ${secondaryColor}!important;
          color: ${primaryColor}!important;
          transition: background-color 0.3s, color 0.3s;
        }

        button.enabled:hover {
          background-color: ${primaryColor}!important;
          color: ${secondaryColor}!important;
        }

        a {
          color: ${secondaryColor}!important;
        }

        a:hover {
          color: ${highlightColor}!important;
        }

        .rowIcon {
          width: 96px;
          height: 96px;
          fill: ${secondaryColor}!important;
        }

        .socialIcon {
          width: 24px;
          height: 24px;
          fill: ${secondaryColor}!important;
        } 

        #hero {
          background-image: url("${imageHeader}");
        }

        #hero .img-container .img {
          background-image: url("${imageGimmick}");
        }

        #cta .image-container {
          grid-column: 1/2;
          grid-row: 1/2;
          background-image: url("${imageSquare}");
        }
        
        #cta .image-container .image {
          background-image: url("${imageSquare}");
        }

        `
      }
    </style>
    <title>{ t('title', {eventName: eventConfig.name}) }</title>
    <div className="page">
      <div className="page-main">
        <MainContent eventConfig={eventConfig} />
      </div>
      <FooterContent eventConfig={eventConfig} />
    </div>
  </>;
}