import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationGerman from './locales/de/translation.json';
import translationEnglish from './locales/en/translation.json';

const resources = {
  de: { translation: translationGerman },
  en: { translation: translationEnglish }
}

i18next
  .use(initReactI18next)
  .init(
    {
      fallbackLng: 'en',
      debug: true,
      lng: navigator.language,
      interpolation: {
        escapeValue: false,
      },
      resources
    }
  );

/* Change the language of i18next. Currently available en, de, nl. */
export function changeLanguage(languageCode: string) {
  i18next.changeLanguage(languageCode);
  return i18next.language;
}